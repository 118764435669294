.App {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.utilization-bar {
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.utilization-fill {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease-in-out;
}

.utilization-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-weight: bold;
}

h1 {
  margin-bottom: 20px;
}

.canvas-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.color-picker {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.color-picker button {
  width: 30px;
  height: 30px;
  margin: 0 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}

canvas {
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
}

.playback-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.frame-rate-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.frame-rate-control label {
  margin-bottom: 5px;
}

.frame-rate-control input {
  width: 200px;
}

.donate-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.donate-button:hover {
  background-color: #45a049;
}

.main-donate-button {
  margin-top: 20px;
}

.donate-button-container {
  margin-top: 20px;
}

stripe-buy-button {
  --button-font-family: inherit;
  --button-font-size: 16px;
  --button-border-radius: 4px;
  --button-color: #4CAF50;
  --button-color-hover: #45a049;
}

.pan-instructions {
  margin-bottom: 10px;
  font-style: italic;
  color: #666;
}
